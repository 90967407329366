<template>
  <div fluid="md" class="mt-3" v-if="isLoggedIn">
    <h1 class="mb-3">
      {{ $t('tra.td') }}
    </h1>
    <b-card class="mt-3" header-tag="nav" body-class="p-2 p-sm-4">
      <template #header>
        <b-nav card-header tabs>
          <b-nav-item :to="`/transactions/` + $route.params.uuid" link-classes="px-2 px-sm-3" exact exact-active-class="active">
            <b-icon-file-text v-if="!isXs" v-bind="iH" /> {{ $t('tra.hde') }}
          </b-nav-item>
          <b-nav-item v-if="checkForReserv" :to="`/reservations/` + getResUuid" link-classes="px-2 px-sm-3" exact exact-active-class="active">
            <b-icon-calendar2-check v-if="!isXs" v-bind="iH" /> {{ $t('tra.hrs') }}
          </b-nav-item>
          <b-nav-item v-if="checkForRest" :to="`/transactions/` + $route.params.uuid +'/restaurant'" link-classes="px-2 px-sm-3" exact exact-active-class="active">
            <b-icon-shop v-if="!isXs" v-bind="iH" /> {{ $t('tra.hre') }}
          </b-nav-item>
        </b-nav>
      </template>

      <router-view />
    </b-card>
  </div>
</template>

<script>
import { BIconShop, BIconFileText, BIconCalendar2Check } from 'bootstrap-vue';
import { REST } from '@/components/RestCall';

export default {
  computed: {
    isLoggedIn() {
      return this.$store.state.oLogin.isLoggedIn;
    },
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    item() {
      return this.$store.state.oTraDetails;
    },
    iH() {
      return this.$store.getters.iH;
    },
    isXs() {
      return this.$store.getters.isXs;
    },
    checkForRest() {
      return this.isUser && this.item !== null && this.item.senderUuid4 === this.$store.getters.uuid && this.item.type === 'PAYMENT';
    },
    checkForReserv() {
      return this.item !== null && Object.prototype.hasOwnProperty.call(this.item, 'reservationUuid4') && this.item.reservationUuid4 !== null;
    },
    getResUuid() {
      return this.item.reservationUuid4;
    },
  },
  created() {
    if (!this.isLoggedIn) {
      this.$router.replace('/').catch(() => {});
      return;
    }

    this.$store.commit('setTraRelated', null);
    REST.get('/transactions/' + this.$route.params.uuid + '/' + (this.isUser ? 'user' : 'customer')
      // ).catch( // FAKE DATA
      //   () => { return Promise.resolve(); }
      // ).then(
      //   () => {
      //     return Promise.resolve({ d: { data: {
      //       'senderUuid4': '12345678-4444-4444-bbbb-1234567891234',
      //       'receiverUuid4': '4455667788-1234-4321-abcd-123412345555',
      //       'amount': 3599,
      //       'currency': 'EUR',
      //       'tax': 1900,
      //       'ref': 'B231-A2',
      //       'uuid4': '33332222-1111-4444-bbbb-aaaabbbbcccc',
      //       'state': 'PREPARED',
      //       'receiverName': 'Full restaurant name',
      //       'tip': null,
      //       'createdAt': '2021-02-09 16:20',
      //       'data': {}
      //     }}});
      //   }
      ).then(resp => {
        this.$store.commit('setTraDetails', resp.d.data);
        if (!this.isUser && this.checkForReserv && this.item.type === 'PAYMENT' && this.item.state === 'COMPLETED') {
          REST.get('/transactions/' + this.$route.params.uuid + '/transactions'
          // ).catch( // FAKE DATA
          //   () => { return Promise.resolve(); }
          // ).then(
          //   () => {
          //     return Promise.resolve({ d: { data: [{
          //       'receiverName': null,
          //       'tip': null,
          //       'data': null,
          //       'uuid4': 'aaf3599e-43c6-4686-8d9d-d20e8a318ac4',
          //       'senderUuid4': '1a396f0e-f2ce-4ca6-8970-46f41f89a1f4',
          //       'receiverUuid4': '00000000-0000-4000-a000-000000000000',
          //       'reservationUuid4': null,
          //       'amount': 101,
          //       'currency': 'EUR',
          //       'tax': 1900,
          //       'ref': 'test 10',
          //       'state': 'COMPLETED',
          //       'type': 'PAYMENTFEE',
          //       'createdAt': '2021-05-20 21:47'
          //     }]}});
          //   }
          ).then(resp => {
            this.$store.commit('setTraRelated', resp.d.data);
          }).catch(() => {});
        }
      }).catch(() => {
        this.$router.push('/');
        this.$store.commit('showWarn',this.$t('rc.4'));
        this.$store.commit('setTraDetails', null);
      });
  },
  beforeRouteUpdate(to, from, next) {
    if (this.item === null || to.params.uuid != from.params.uuid || (!from.name.startsWith('AnuTransactions'))) {// && this.item.uuid4 !== to.params.uuid)) {
      this.$store.commit('setTraRelated', null);
      REST.get('/transactions/' + to.params.uuid + '/' + (this.isUser ? 'user' : 'customer')
          // ).catch( // FAKE DATA
          //   () => { return Promise.resolve(); }
          // ).then(
          //   () => {
          //     return Promise.resolve({ d: { data: {
          //       'senderUuid4': '12345678-4444-4444-bbbb-1234567891234',
          //       'receiverUuid4': '4455667788-1234-4321-abcd-123412345555',
          //       'amount': 3599,
          //       'currency': 'EUR',
          //       'tax': 1900,
          //       'ref': 'B231-A2',
          //       'uuid4': '33332222-1111-4444-bbbb-aaaabbbbcccc',
          //       'state': 'PREPARED',
          //       'receiverName': 'Full restaurant name',
          //       'tip': null,
          //       'createdAt': '2021-02-09 16:20',
          //       'data': {}
          //     }}});
          //   }
        ).then(resp => {
          this.$store.commit('setTraDetails', resp.d.data);

          if (!this.isUser && this.checkForReserv && this.item.type === 'PAYMENT' && this.item.state === 'COMPLETED') {
            REST.get('/transactions/' + this.$route.params.uuid + '/transactions'
            ).then(resp => {
              this.$store.commit('setTraRelated', resp.d.data);
            }).catch(() => {}
            ).finally(() => {
              next();
            });
          } else {
            next();
          }
        }).catch(() => {
          next('/');
          this.$store.commit('setTraDetails', null);
          this.$store.commit('showWarn',this.$t('rc.4'));
        })
    } else {
      next();
    }
  },
  components: {
    BIconShop,
    BIconFileText,
    BIconCalendar2Check,
  },
}
</script>
